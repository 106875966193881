/* eslint-disable */
import React, { useState, useEffect  } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";
import SwiperCore, { Navigation, A11y, Pagination, Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Button from '../../common/Button'
import 'swiper/swiper.scss';
import '../../../../node_modules/swiper/components/effect-fade/effect-fade.scss';

import IsMobile from "../../common/IsMobile";
import IsDesktop from "../../common/IsDesktop";

SwiperCore.use([Navigation, Pagination, A11y, Autoplay, EffectFade]);

const MainContainer = styled.div`
  position: relative;

  .swiper,
  .swiper-container {
    height: 100%;
  }

  .swiper-button-prev,
  .swiper-button-next {
    cursor: pointer;
    position: absolute;
    z-index: 20;
    width: 40px;
    text-align: center;
    background: #5375B4;
    border-radius: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .swiper-container-fade .swiper-slide-active {
    z-index: 2;
  }

  .swiper-button-prev {
    top: calc(50% - 25px);
    left: 10px;

    @media screen and (max-width: 40em) {
      top: 220px;
    }
  }

  .swiper-button-next {
    top: calc(50% - 20px);
    right: 10px;
    transform: rotate(180deg);

    @media screen and (max-width: 40em) {
      top: 220px;
    }
  }

  .swiper-button-prev:before,
  .swiper-button-next:before {
    content:"‹";
    font-size: 60px;
    line-height: 1;
    font-weight: bold;
    color: #fff;
    position: relative;
    top: -4px;
    left: -2px;
    ${tw`font-montserrat`}
  }

  .swiper-button-prev:before {
    top: -5px;
    left: -3px;
  }

  .swiper-button-disabled {
    display: none;
  }
`

const SlideContainer = styled.div`
  display: block;
  width: 100%;
  height: 100%;

  @media screen and (min-width: 64em) {
    display: flex;
    flex-direction: row-reverse;
  }
`

const Left = styled.div`
  flex-basis: 32%;
  padding: 2rem 2rem;

  @media screen and (min-width: 64em) {
    padding-left: 80px;
    padding-right: 50px;
  }

  @media screen and (max-width: 40em) {
    flex-basis: auto;
  }
`

const Right = styled.div`
  flex-basis: 68%;
  position: relative;

  img {
    width: 100%;
    height: auto;
    max-width: 100%;
  }

  @media screen and (max-width: 40em) {
    height: auto;
    flex-basis: auto;
  }
`

const Header = styled.h2`
  ${tw`font-raleway text-4xl font-light text-black leading-tight m-0 mb-6`}
  width: 100%;
`


const Body = styled.p`
  ${tw`font-raleway font-normal text-black leading-loose m-0 mb-6`}
  width: 100%;
`

const TextWrapper = styled.div`
  max-width: 400px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
`



const PaperButton = styled(Button)`
  background: #5375B4;
  color: #fff;
  white-space: normal !important;
  font-size: .75rem;
  border: 1px solid #5375B4;
  &:hover,
  &:focus {
    background: #fff;
    color: #5375B4;
    box-shadow: 0 0 0 3px #738dbd;
    border-color: #5375B4;
  }
`

const PaperButtons = styled.div`
  display: flex;
  ${PaperButton} {
    margin: 0 10px 0 0;
  }
  @media screen and (max-width: 500px) {
    display: block;
    ${PaperButton} {
      margin: 10px 0;
      width: 100%;
    }
  }
`


const HomeHeader = ({locale}) => {
  return (
    <MainContainer>
      <Swiper
        navigation
        a11y
        effect="fade"
        fadeEffect={{ crossFade: true }}
        autoHeight
        autoplay={{delay:5000}}
      >

<SwiperSlide>
          <SlideContainer>
            <Right>
              <IsDesktop>
              <img
                src={locale === 'fr' 
                  ? "https://img.imageboss.me/royale-images/width/1600/format:webp/images/Proudly-Canadian_Web_FR.png" 
                  : "https://img.imageboss.me/royale-images/width/1600/format:webp/images/Proudly-Canadian_Web_EN_V2.png"}
                alt="Royale"
              />
              </IsDesktop>
              <IsMobile>
              <img
                src={locale === 'fr' 
                  ? "https://img.imageboss.me/royale-images/width/1600/format:webp/images/Proudly-Canadian_Mobile_FR.png" 
                  : "https://img.imageboss.me/royale-images/width/1600/format:webp/images/Proudly-Canadian_Mobile_EN_V2.png"}
                alt="Royale"
              />
              </IsMobile>
            </Right>
            <Left>
              <TextWrapper>
                <Header>
                  <FormattedHTMLMessage id="canadian.title1" />
                </Header>
                <Body>
                  <FormattedHTMLMessage id="canadian.text1" />
                </Body>
              </TextWrapper>
            </Left>
          </SlideContainer>
        </SwiperSlide>

        <SwiperSlide>
          <SlideContainer>
            <Right>
              <IsDesktop>
              <img
                src="https://img.imageboss.me/royale-images/width/1600/format:webp/images/Royale-FacialPI-Website-HomePage_Desktop.png"
                alt="Royale"
              />
              </IsDesktop>
              <IsMobile>
              <img
                src="https://img.imageboss.me/royale-images/width/585/format:webp/images/Royale-FacialPI-Website-HomePage_Mobile.png"
                alt="Royale"
              />
              </IsMobile>
            </Right>
            <Left>
              <TextWrapper>
                <Header>
                  <FormattedHTMLMessage id="facial.hero1" />
                </Header>
                <Body>
                  <FormattedHTMLMessage id="facial.hero2" />
                </Body>
                { locale === 'fr' ?
                  <PaperButton to="/fr/produits/mouchoirs/" modification="pets" convert={false}>
                    Plus d’info
                  </PaperButton>
                  :
                  <PaperButton to="/products/facial-tissue/" modification="pets" convert={false}>
                    Learn more
                  </PaperButton>
                }
              </TextWrapper>
            </Left>
          </SlideContainer>
        </SwiperSlide>
        <SwiperSlide>
          <SlideContainer>
            <Right>
              <IsDesktop>
              <img
                src={locale === 'fr' 
                  ? "https://img.imageboss.me/royale-images/width/1600/format:webp/images/Web_ClusterPacks_HomePage_FR.jpg" 
                  : "https://img.imageboss.me/royale-images/width/1600/format:webp/images/Web_ClusterPacks_HomePage_EN.jpg"}
                alt="Royale"
              />
              </IsDesktop>
              <IsMobile>
              <img
                src={locale === 'fr' 
                  ? "https://img.imageboss.me/royale-images/width/585/format:webp/images/Web_ClusterPacks_HomePage_FR.jpg" 
                  : "https://img.imageboss.me/royale-images/width/585/format:webp/images/Web_ClusterPacks_HomePage_EN.jpg"}
                alt="Royale"
              />
               
              </IsMobile>
            </Right>
            <Left>
              <TextWrapper>
                <Header>
                  <FormattedHTMLMessage id="carbon.hero1" />
                </Header>
                <Body>
                  <FormattedHTMLMessage id="carbon.hero2" />
                </Body>
                { locale === 'fr' ?
                  <PaperButton to="/fr/carboneutre" aria-label="Plus d’info" title="Plus d’info" modification="pets" convert={false}>
                    Plus d’info
                  </PaperButton>
                  :
                  <PaperButton to="/carbon-neutral" aria-label="Learn more" title="Learn more" modification="pets" convert={false}>
                    Learn more
                  </PaperButton>
                }
              </TextWrapper>
            </Left>
          </SlideContainer>
        </SwiperSlide>
        <SwiperSlide>
          <SlideContainer>
            <Right>
              <IsDesktop>
              <img
                  src={locale === 'fr' 
                    ? "https://img.imageboss.me/royale-images/width/1600/format:webp/images/facialcubes_home_fr.jpg" 
                    : "https://img.imageboss.me/royale-images/width/1600/format:webp/images/facialcubes_home_en.jpg"}
                  alt="Royale"
                />
              </IsDesktop>
              <IsMobile>
              <img
                src={locale === 'fr' 
                  ? "https://img.imageboss.me/royale-images/width/585/format:webp/images/facialcubes_home_fr_mobile.jpg" 
                  : "https://img.imageboss.me/royale-images/width/585/format:webp/images/facialcubes_home_en_mobile.jpg"}
                alt="Royale"
              />
              </IsMobile>
            </Right>
            <Left>
              <TextWrapper>
                <Header>
                  <FormattedHTMLMessage id="paperpack.text1" />
                </Header>
                <Body>
                  <FormattedHTMLMessage id="paperpack.text3" />
                </Body>
                <PaperButtons>
                  { locale === 'fr' ?
                    <PaperButton aria-label="Plus d’info" title="Plus d’info" to="/fr/produits/mouchoirs#121a0dec-b670-47f7-801a-970a945fd1e1" modification="pets" convert={false}>
                      Plus d’info
                    </PaperButton>
                    :
                    <PaperButton aria-label="Learn more" title="Learn more" to="/products/facial-tissue#2a5a3a6b-6206-42ab-b752-1da88e52dcad" modification="pets" convert={false}>
                      Learn more
                    </PaperButton>
                  }
                </PaperButtons>
              </TextWrapper>
            </Left>
          </SlideContainer>
        </SwiperSlide>
      </Swiper>
    </MainContainer>
  )
}

HomeHeader.propTypes = {
  // Text that shows at the top
  frontHeaderText: PropTypes.string,
  // Any additional menu elements (used for homepage)
  // Used to change languages
  urls: PropTypes.shape({
    en: PropTypes.string.isRequired,
    fr: PropTypes.string.isRequired,
    zh_hans: PropTypes.string.isRequired,
    zh_hant: PropTypes.string.isRequired,
  }),
  // used to determine current langage
  locale: PropTypes.string
}

HomeHeader.defaultProps = {
  frontHeaderText: '',
  locale: 'en'
}

export default HomeHeader
